import React, { Component } from 'react'
import Link from '../utils/link'

import Logo from '../assets/images/logo.svg'

class Header extends Component {

  state = {
    offCanvas: false,
    scrolled: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
    setTimeout(() => {
      if ( document.querySelector('body').classList.contains('home') ) {
        this._setSpanLeft('-11px', '63px')
      }
      // About
      if ( document.querySelector('body').classList.contains('about') ) {
        this._setSpanLeft('61px', '65px')
      }
      // Services + Single Service
      if ( document.querySelector('body').classList.contains('services') || document.querySelector('body').classList.contains('single-service') ) {
        this._setSpanLeft('133px', '81px')
      }
      // Practitioners + Single Practitioner
      if ( document.querySelector('body').classList.contains('practitioners') || document.querySelector('body').classList.contains('single-team') ) {
        this._setSpanLeft('222px', '106px')
      }
      // Contact 
      if ( document.querySelector('body').classList.contains('contact') ) {
        this._setSpanLeft('340px', '77px')
      }
      // Blog + Single Post
      if ( document.querySelector('body').classList.contains('blog-template') || document.querySelector('body').classList.contains('single-post') ) {
        this._setSpanLeft('426px', '50px')
      }
    }, 100)
    // if development mode then don't track
    if (process.env.NODE_ENV === 'production') typeof window !== "undefined" && window.gtag('config', 'AW-982662112/uuSwCMmr08AYEOD3yNQD', { 'phone_conversion_number': '(03) 9899 0399' })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  onScroll = () => {
    this.setState({
      scrolled: window.scrollY > 1,
    })
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _eventTracking = () => {
    typeof window !== "undefined" && window.gtag("event", "click", {'event_category': 'Button', 'event_label': 'Book Now', 'value': 1})
    this.setState({ offCanvas: false })
  }

  _hideOffCanvasHomeClick = () => {
    this.setState({ offCanvas: false })
    document.querySelector('.header__nav span').style.left = '-11px'
    document.querySelector('.header__nav span').style.width = '63px'
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  _setSpanLeft = (i, w) => {
    document.querySelector('.header__nav span').style.left = i
    document.querySelector('.header__nav span').style.width = w
  }

  _checkPosition = (event) => {
    if ( event.clientY > 47 || event.clientY < 29 ) {
      // Home
      if ( document.querySelector('body').classList.contains('home') ) {
        this._setSpanLeft('-11px', '63px')
      }
      // About
      if ( document.querySelector('body').classList.contains('about') ) {
        this._setSpanLeft('61px', '65px')
      }
      // Services + Single Service
      if ( document.querySelector('body').classList.contains('services') || document.querySelector('body').classList.contains('single-service') ) {
        this._setSpanLeft('133px', '81px')
      }
      // Practitioners + Single Practitioner
      if ( document.querySelector('body').classList.contains('practitoners') || document.querySelector('body').classList.contains('single-team') ) {
        this._setSpanLeft('222px', '106px')
      }
      // Contact 
      if ( document.querySelector('body').classList.contains('contact') ) {
        this._setSpanLeft('340px', '77px')
      }
      // Blog + Single Post
      if ( document.querySelector('body').classList.contains('blog-template') || document.querySelector('body').classList.contains('single-post') ) {
        this._setSpanLeft('426px', '50px')
      }
    }
  }

  render() {

    let { offCanvas, scrolled } = this.state

    let props = {
      onClick: this._hideOffCanvas,
    }
    let bookNowProps = {
      onClick: this._eventTracking,
    }
    let logoProps = {
      onClick: this._hideOffCanvasHomeClick,
    }

    let headerClass = 'header'
    if (scrolled) headerClass += ' header--scrolled'

    return (
      <>
        <header className={headerClass}>
          <div className='header__inner'>
            <Link to='/' title='Surrey Hills Physiotherapy' className='header__logo' {...logoProps}>
              <img src={Logo} alt='Surrey Hills Physiotherapy' />
            </Link>
            <nav className='header__nav'>
              <ul>
                <li onClick={() => this._setSpanLeft('-11px', '63px')} onMouseEnter={() => this._setSpanLeft('-11px', '63px')} onMouseLeave={(e) => this._checkPosition(e)}><Link to='/' {...props}>Home</Link></li>
                <li onClick={() => this._setSpanLeft('61px', '65px')} onMouseEnter={() => this._setSpanLeft('61px', '65px')} onMouseLeave={(e) => this._checkPosition(e)}><Link to='/about/' {...props}>About</Link></li>
                <li onClick={() => this._setSpanLeft('133px', '81px')} onMouseEnter={() => this._setSpanLeft('133px', '81px')} onMouseLeave={(e) => this._checkPosition(e)}><Link to='/services/' {...props}>Services</Link></li>
                <li onClick={() => this._setSpanLeft('222px', '106px')} onMouseEnter={() => this._setSpanLeft('222px', '106px')} onMouseLeave={(e) => this._checkPosition(e)}><Link to='/practitioners/' {...props}>Practitioners</Link></li>
                <li onClick={() => this._setSpanLeft('340px', '77px')} onMouseEnter={() => this._setSpanLeft('340px', '77px')} onMouseLeave={(e) => this._checkPosition(e)}><Link to='/contact/' {...props}>Contact</Link></li>
                <li onClick={() => this._setSpanLeft('426px', '50px')} onMouseEnter={() => this._setSpanLeft('426px', '50px')} onMouseLeave={(e) => this._checkPosition(e)}><Link to='/blog/' {...props}>Blog</Link></li>
                <li onClick={() => this._setSpanLeft('505px', '65px')} onMouseEnter={() => this._setSpanLeft('505px', '65px')} onMouseLeave={(e) => this._checkPosition(e)}><Link className='btn' to='https://surrey-hills-physiotherapy.au3.cliniko.com/bookings' {...bookNowProps}>Book Now</Link></li>
                <span />
              </ul>
            </nav>
            <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
              <span className='lines'></span>
            </button>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <ul>
                <li><Link to='/about/' {...props}>About</Link></li>
                <li><Link to='/services/' {...props}>Services</Link></li>
                <li><Link to='/practitioners/' {...props}>Practitioners</Link></li>
                <li><Link to='/contact/' {...props}>Contact</Link></li>
                <li><Link to='/blog/' {...props}>Blog</Link></li>
                <li><Link className='btn' to='https://surrey-hills-physiotherapy.au3.cliniko.com/bookings' {...bookNowProps}>Book Now</Link></li>
              </ul>
            </nav>
          </div>
        </div>
      </>
    )
  }
}

export default Header
