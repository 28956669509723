import React, { Component } from 'react'
import Link from '../utils/link'

import Facebook from '../assets/images/facebook.svg'
import Instagram from '../assets/images/instagram.svg'

class Footer extends Component {

  _setSpanLeft = () => {
    document.querySelector('.header__nav span').style.left = '340px'
    document.querySelector('.header__nav span').style.width = '77px'
  }

  render() {
    return (
      <footer className='footer'>
        <div className='footer__top'>
          <div className='footer__inner'>
            <p>Want to find out more?</p>
            <Link className='btn' to='/contact/'  onClick={this._setSpanLeft}>Get in Touch</Link>
          </div>
        </div>
        <div className='footer__bottom'>
          <div className='footer__inner'>
            <div className='footer__cols'>
              <div className='footer__col'>
                <p>Surrey Hills Physiotherapy<br />1/109 Union Rd Surrey Hills VIC, 3127</p>
                <ul>
                  <li><Link to='https://www.facebook.com/surreyhillsphysiotherapy/'><img src={Facebook} alt='Facebook' /></Link></li>
                  <li><Link to='https://www.instagram.com/surreyhillsphysiotherapy/'><img src={Instagram} alt='Instagram' /></Link></li>
                </ul>
              </div>
              <div className='footer__col'>
                <p><Link to='tel:+61398990399'>(03) 9899 0399</Link><br />
                <Link to='mailto:info@surreyhillsphysio.com.au'>info@surreyhillsphysio.com.au</Link></p>
              </div>
              <div className='footer__col'>
                <p>Surrey Hills Physiotherapy acknowledges the traditional owners of the land upon which our health service is built, the Wurundjeri People, part of the Kulin Nation, and pays our respects to their Elders, past, present and emerging.</p>
                <p>Surrey Hills Physiotherapy is an inclusive healthcare provider</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
